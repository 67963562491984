var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "couponUserListTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "highlight-current-row": "",
            align: "center",
            "empty-text": _vm.$t("coupon.user.tableEmpty")
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", align: "center" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("coupon.user.keys.email"),
              prop: "name",
              align: "center",
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.email))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("coupon.user.keys.period"),
              prop: "usePeriod",
              align: "center",
              width: "230"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.benefit_start_date,
                            "{y}-{m}-{d}"
                          )
                        ) +
                          " ~ " +
                          _vm._s(
                            _vm._f("parseTime")(
                              scope.row.benefit_end_date,
                              "{y}-{m}-{d}"
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("coupon.user.keys.type"),
              prop: "couponType",
              align: "center",
              width: "250"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.filterCouponType(scope.row.coupon_type))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("coupon.user.keys.code"),
              prop: "couponType",
              align: "center",
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.code))])]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        staticClass: "pagination",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          "page-sizes": [5, 10],
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }