import request from '@/utils/request';
export var getCouponList = function getCouponList(params) {
  return request({
    url: '/user/api/v1/coupon/event/list',
    method: 'get',
    params: params
  });
};
export var getCouponDetail = function getCouponDetail(params) {
  return request({
    url: '/user/api/v1/coupon/event/detail',
    method: 'get',
    params: params
  });
};
export var overlapCouponName = function overlapCouponName(params) {
  return request({
    url: '/user/api/v1/coupon/event/nameOverlap',
    method: 'get',
    params: params
  });
};
export var overlapCouponCode = function overlapCouponCode(params) {
  return request({
    url: '/user/api/v1/coupon/event/welcome/overlap',
    method: 'get',
    params: params
  });
};
export var createCoupon = function createCoupon(data) {
  return request({
    url: '/user/api/v1/coupon/event/detail',
    method: 'post',
    data: data
  });
};
export var updateCoupon = function updateCoupon(data) {
  return request({
    url: '/user/api/v1/coupon/event/detail',
    method: 'put',
    data: data
  });
};
export var getCouponUserList = function getCouponUserList(params) {
  return request({
    url: '/user/api/v1/coupon/event/user/list',
    method: 'get',
    params: params
  });
};